<template>
  <div>
    <Header />
    <div class="m-conbbox" style="padding-top: 80px;">
      <MyBanners :navIndex="7" />
      <div class="wp1200" style="padding-top: 20px;">
        <div class="g-typesb1">
          <ul class="ul-tabsb1">
            <!-- <li
              :class="params.belong == 1 ? 'on' : ''"
              @click="params.belong = 1; getTypeList(); getList();"
            >
              <div class="s">渠道发展</div>
            </li> -->
            <li
              :class="params.belong == 2 ? 'on' : ''"
              @click="params.belong = 2; getTypeList(); getList();"
            >
              <div class="s">产品资料</div>
            </li>
          </ul>
          <div class="tabbox">
            <div class="m-lineb1" style="position: relative;padding-right: 80px;">
              <span class="l">类型：</span>
              <div class="oh">
                <a
                  :class="['a1',params.infotypeName == '' ? 'act' : '']"
                  @click="params.infotypeName = ''; params.pageNo = 1; list = [];params.typeIdList = [];; getList();"
                >全部</a>
				
				<a
                  :class="['a1', params.infotypeName == item.name ? 'act' : '']"
				    v-for="(item, index) in showTypeList"
				    :key="index"
				    @click="changetype(item)"
				>{{ item.name }}</a>
              </div>
              <span
                style="color: #e83421;cursor: pointer;white-space: nowrap;position: absolute;right: 20px;top: 0;"
                v-show="!showMore && lengthMore"
                @click="showMore = !showMore"
              >查看更多</span>
              <span
                style="color: #e83421;cursor: pointer;white-space: nowrap;position: absolute;right: 20px;top: 0;"
                v-show="showMore && lengthMore"
                @click="showMore = !showMore"
              >收起</span>
            </div>
            <div class="m-lineb1">
              <span class="l">格式：</span>
              <div class="oh">
                <a
                  class="a1"
                  :class="params.format == '' ? 'act' : ''"
                  @click.prevent="params.format = ''; getList();"
                >全部</a>
                <a
                  v-for="(item, index) in formats"
                  :key="index"
                  class="a1"
                  :class="params.format == item ? 'act' : ''"
                  @click.prevent="params.format = item; getList();"
                >{{ item }}</a>
              </div>
            </div>
          </div>
        </div>
        <ul class="ul-listb1" style="margin-bottom: 45px;">
          <li class="g-downb1" v-for="(item, index) in list" :key="index">
            <div class="con">
              <div class="left">
                <div class="icon">
                  <img :src="item.src" alt />
                </div>
                <div class="txt">
                  <div class="name">{{ item.title }}</div>
                  <div class="info">{{ item.createTime }} I {{ item.infoSize }}</div>
                </div>
              </div>
              <div class="right">
                <div class="g-optionsb1">
                  <div class="btn" v-if="item.infoFormat == 'pdf'" @click="priview(item)">
                    <div class="icn">
                      <img src="@/assets/images/icon_preview.png" alt />
                    </div>
                    <span class="s">预览</span>
                  </div>
                  <div
                    class="btn"
                    v-if="(item.infoFormat != 'pdf' && item.infoContent != '') || item.infoFormat == '视频'"
                    @click="toDetail(item)"
                  >
                    <div class="icn">
                      <img src="@/assets/images/icon_preview.png" alt />
                    </div>
                    <span class="s">查看</span>
                  </div>
                  <div class="btn" v-if="item.infoFormat != '视频'" @click="showDownload(item)">
                    <div class="icn">
                      <img src="@/assets/images/icon_download.png" alt />
                    </div>
                    <span class="s">下载</span>
                  </div>
                  <div class="btn" @click="collection(item.id, index, item.collect)">
                    <div class="icn">
                      <img v-if="item.collect" src="@/assets/images/icon_faved.png" alt />
                      <img v-else src="@/assets/images/icon_fav.png" alt />
                    </div>
                    <span class="s">{{ item.collect ? '取消收藏' : '收藏' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- 内容为空 -->
        <ContentNull style="display: none;" />
        <div class="pagination-box style2" v-loading.fullscreen.lock="false">
          <el-pagination
            :total="total"
            :page-size="params.pageSize"
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <Download :info="downInfo" ref="downloadRef" @confirm="download" />
    <Footer />
  </div>
</template>
<script >
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import MyBanners from '@/components/MyBanners.vue';
import Download from '@/components/Download.vue';
import ContentNull from '@/components/ContentNull.vue';
import Api from '@/request/api';
import { mapState } from 'vuex';
export default {
  name: 'Mydown',
  components: {
    Header,
    Footer,
    MyBanners,
    Download,
    ContentNull
  },
  data() {
    return {
      params: {
        belong:2,
        format: '',
        order: '',
        pageNo: 1,
        pageSize: 10,
		infotypeName:"",
		typeIdList:[],
        sort: 0,
        type: '',
        type2: 4 // 3 收藏
      },
      total: 0,
      list: [],
      showMore: false,
      typeList: [],
      downInfo: {}
    }
  },
  created() {
    this.getTypeList()
    this.getList()
  },
  watch: {
    $route(v) {
      if (v.path == '/mydown') {
        this.getList()
      }
    }
  },
  computed: {
    ...mapState([
      'formats'
    ]),
    lengthMore() {
      if (this.typeList.length > 8) {
        return true
      } else {
        return false
      }
    },
    showTypeList() {
      if (this.showMore) {
        return this.typeList
      } else {
        return this.typeList.splice(0, 8)
      }
    }
  },
  methods: {
    getList() {
		  this.loading = true
		  Api.myReadList(this.params).then(res => {
			  try {
				  for (let i = 0; i < res.records.length; i++) {
					  res.records[i].src = require('@/assets/images/file_' + this.getFormatType(res.records[i].infoFormat) + '.png')
				  }
			  } catch {
			  }
			  this.list = res.records
			  this.total = res.total
			  this.loading = false
		  }).catch(err => { this.loading = false })
    },
    // 加载类型
    getTypeList() {
	  Api.typeList({ belong: this.params.belong, pid:0}).then(res => {
		let data = [];
		Object.keys(res).map(item=>{
			let ids = [];
			res[item].map(value=>{
				ids.push(value.id);
			})
			data.push({name:item,id:ids});
		})
		  this.typeList = data
	  })
    },
    handleCurrentChange(v) {
      this.params.pageNo = v
      this.getList()
    },
    // 跳转到详情页面
    toDetail(item) {
		if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
			this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
				
				Api.doApply({inforClassId:item.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
					this.$message.success("申请已提交，请耐心等待");
				})
			})
		}else{
			if (item.infoFormat == '链接') {
			    window.location = item.document
			} else {
			    this.$router.push({ path: '/detail', query: { id: item.id, type: 2 } })
			}
		}
    },
    // 预览
    priview(e) {
		if((e.applyType == 0 && e.applyStatus == 1) || (e.applyType == 0 && e.applyStatus==undefined)){
			this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
				
				Api.doApply({inforClassId:e.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
					this.$message.success("申请已提交，请耐心等待");
				})
			})
		}else{
			const data = {
			    belong: 2,
			    id: e.id,
			    type: 1
			}
			Api.inforPosts(data).then(res => {
			    console.log(res)
			    if (res.document) {
			        // window.location = res.document
			        window.open(res.document.replace('http', 'https'))
			    }
			})
		}
      // window.location = url
    },
    // 显示下载弹窗
    showDownload(item) {
		if((item.applyType == 0 && item.applyStatus == 1) || (item.applyType == 0 && item.applyStatus==undefined)){
			this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
				
				Api.doApply({inforClassId:item.infoTypeId,typeIdList:this.params.typeIdList}).then(res=>{
					this.$message.success("申请已提交，请耐心等待");
				})
			})
		}else{
			this.downInfo = {
			    id: item.id,
			    title: item.title,
			    url: item.document,
			    icon: item.infoFormat,
				fileName:item.fileName?item.fileName:''
			}
			this.$refs.downloadRef.open()
		}
    },
    // 下载
    download() {
      const data = {
        belong: this.params.belong,
        id: this.downInfo.id,
        type: 4
      }
      Api.inforPosts(data).then(res => {
        // window.location = res.document
        window.open(res.document.replace('http', 'https'))
      })
      this.$refs.downloadRef.close()
    },
	
	changetype(e){
		// if((e.applyType == 0 && e.applyStatus == 1) || (e.applyType == 0 && e.applyStatus==undefined)){
		// 		this.$confirm("您尚未开通该目录权限，是否申请开通？","提示").then(()=>{
		// 			Api.doApply({inforClassId:e.id}).then(res=>{
		// 				this.$message.success("申请已提交，请耐心等待");
		// 			})
		// 		})
		// }else{
			this.params.infotypeName = e.name;
			this.params.typeIdList = e.id;
			this.params.pageNo = 1;
			this.list = [];
			this.getList();
		// }
		
	},
    // 收藏
    collection(id, index, vlaue) {
      const data = {
        belong: 2,
        id: id,
        type: 3
      }
      if (vlaue) {
        Api.cancelInforPosts(data)
      } else {
        Api.inforPosts(data)
      }
      this.list[index].collect = !vlaue
    },
    // 根据类型返回类
    getFormatType(type) {
      let str = ''
      this.$store.state.infoFormatType.forEach(item => {
        if (item.type == type) {
          str = item.src
          return;
        }
      })
      return str
    }
  },


}
</script>

<style scoped>
@import "~@/assets/css/m-bl.css";
.pagination-box.style2 {
  text-align: center;
  margin-bottom: 60px;
}
@media only screen and (max-width: 750px) {
    .pagination-box.style2 {
        margin-bottom: .6rem;
    }    
}
</style>